/* @v2.0 */
import { pricingItemsAlternate } from 'data/landing/pricingData';
import { PricingGrid } from './PricingItem';
import soon from 'assets/img/spot-illustrations/dark_39.png';

const Pricing = () => {
  return (
    <section id="pricing" className="position-static pt-8 pt-md-5 pt-lg-2">
      <div className="container-small px-lg-7 px-xxl-3 mt-6">
        <div className="text-center mb-3 mb-lg-7">
          <h1 className="text-center text-gradient-info mt-6 mb-6">Pricing</h1>
          <h2 className="mb-8">Choose the best deal for you
          </h2>
          <col></col>
        </div>
       {/*Starting Soon Background*/}
        {/*<div className="container-small px-lg-7 px-xxl-3" style={{ position: 'relative', zIndex: 1 }}>
        {/*<Row className="text-center mb-0">
          <Col
            lg={12}
            className="text-center d-flex flex-column align-items-center justify-content-center"
            style={{ textAlign: 'justify' }}>
            <Col lg={5}>
              {/*<a href="" className="d-block">
                <img
                  src={soon}
                  alt="Linuxcorp Logo"
                  className="img-fluid mt-6 mb-6"
                  style={{ width: 'auto', height: 'auto' }}
                />
              </a>
            </Col>
            <br />
            </Col>
         </Row> */}
        {/*End of Soon Background*/}

        {/* Description with Background Color */}          
        <div className="mb-4 mb-lg-11 mt-15">
          <PricingGrid pricingOptions={pricingItemsAlternate} />
        </div>
      </div>
    </section>
  );
};

export default Pricing;