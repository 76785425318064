import tester from 'assets/img/first.png';

const First = () => {
  const handleBackClick = () => {
    window.history.back();
  };

  return (
    <section
      id="first"
      className="pb-0 overflow-hidden"
      style={{ overflowX: 'hidden', overflowY: 'auto' }}
    >
      <div className="container-fluid position-relative">
        {/* Desktop version */}
        <div className="d-none d-lg-block">
          <div className="row justify-content-center align-items-center h-100 mx-0">
            <div className="col-lg-6 text-center ">
            <button
                onClick={handleBackClick}
                className="btn btn-primary mt-3 ms-3 mb-10"
                style={{
                  zIndex: 10,
                }}
              >
                &lt; BACK
              </button>
              <img
                src={tester}
                alt="Tester Image"
                className="img-fluid"
                style={{
                  maxWidth: '100%', // Restrict image size for desktop
                  height: 'auto',
                }}
              />
            </div>
          </div>
        </div>

        {/* Mobile version */}
        <div className="d-lg-none">
          {/* Row for Back Button */}
          <div className="row justify-content-start mx-0">
            <div className="col-12 text-center">
              <button
                onClick={handleBackClick}
                className="btn btn-primary mt-3 ms-3 mb-10"
                style={{
                  zIndex: 10,
                }}
              >
                &lt; BACK
              </button>
            </div>
          </div>

          {/* Image Row */}
          <div className="row justify-content-center align-items-center mx-0">
            <div className="col-12 text-center">
              <img
                src={tester}
                alt="Tester Image"
                className="img-fluid"
                style={{
                  maxWidth: '100%', // Restrict image size for mobile
                  width: '100%', // Ensure it fits the screen width
                  height: 'auto',
                }}
              />
            </div>
          </div>
        </div>

        {/* Content section */}
        <div className="row justify-content-center align-items-center mt-5">
          <div className="col-12 col-md-8">
            <h1 className="fw-bold mt-6 mb-4 text-center">First Responder & Military Discount Plan</h1>
            <p className="fs-6 text-center">
              We are honored to offer a special discount for verified First Responders and Military personnel. The Basic Plan, normally priced at $12 per month, is available to you for just $10. This discount is our way of showing appreciation for your service and commitment to our community.
            </p>

            <h4 className="mt-8 text-justify">How to Get Your Discount</h4>
            <p className="text-justify">
              To receive the discounted rate, simply upload your First Responder or Military ID to your profile. 
              Once our team has verified your ID, the discount will apply to your next billing cycle.
            </p>
            <p className="text-justify">
              If you upload your ID within 7 days of purchasing the Basic Plan, a $2 refund will be issued to you. 
              For all other cases, the discount will be applied to your next billing cycle.
            </p>

            <h4 className="mt-6 text-justify">Important Details</h4>
            <ul className="list-unstyled text-justify">
              <li><strong>Cost of Basic Plan:</strong> $12</li>
              <li><strong>Discounted Price for Verified First Responders and Military:</strong> $10</li>
              <li><strong>Refund Policy:</strong> If ID is uploaded within 7 days of purchase, a $2 refund will be issued.</li>
              <li><strong>Verification Process:</strong> ID verification by our team is required for the discount to be applied.</li>
              <li><strong>Next Billing Cycle:</strong> Discount applies to the next billing cycle after verification.</li>
            </ul>

            <div className="mt-5">
              <p className="fw-bold text-center">Thank you for your service! We're honored to support you.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default First;
