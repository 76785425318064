/* @v1.0 init */
import Section from 'components/base/Section';
import EcomCartSummaryCard from 'components/cards/EcomCartSummaryCard';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import AddressSection from 'components/modules/landing/alternate/AddressSection';
import Footer from 'components/modules/landing/alternate/Footer';
import DefaultCartNavbar from 'components/navbars/default-landing-navbar/DefaultCartNavbar';
import EcomCartTable from 'components/tables/EcomCartTable';
import { defaultBreadcrumbItems } from 'data/commonData';
import { cartItems } from 'data/e-commerce/products';
import useSettingsMountEffect from 'hooks/useSettingsMountEffect';
import { Card, Col, Row } from 'react-bootstrap';
import bgbiz from 'assets/img/bg/bg-23.png';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useEffect } from 'react';
import { useCart } from 'components/CartContext';

//handleAddToCart
const pricing = {
  category: 'Basic',
  price: 12.0,
  initialStock: 100
};

const Cart = () => {
  const [PartnerUser, setUserName] = useState('');
  const { cart, addToCart, removeFromCart } = useCart();
  const [stockAvailable, setStockAvailable] = useState(pricing.initialStock);
  const itemsInCart = cart.filter(item => item.category === pricing.category)

  .reduce((total, item) => total + (item.quantity || 1), 0);
  const handleAddToCart = () => {
    if (stockAvailable > 0) {
      const existingCartItem = cart.find(item => item.category === pricing.category);
      
      if (existingCartItem) {
        addToCart({
          category: pricing.category,
          price: pricing.price,
          quantity: (existingCartItem.quantity || 1) + 1
        });
      } else {
        addToCart({
          category: pricing.category,
          price: pricing.price,
          quantity: 1
        });
      }
      setStockAvailable((prev) => prev - 1);
    }
  };
  
  useEffect(() => {
    handleAddToCart();
    const path = window.location.pathname;
    const pathSegments = path.split('/');
    const user = pathSegments[pathSegments.length - 1];
    setUserName(user);
  }, []);
  return (
      <>
    <DefaultCartNavbar />
    <div className="bg-body-emphasis dark__bg-gray-1200" style={{
      backgroundImage: `url(${bgbiz})`,
      backgroundSize: 'contain', // Change to 'contain' to fit within the section
      backgroundPosition: 'center',
      zIndex: 0,
      height: '100%',
      width: '100%',
      backgroundRepeat: 'no-repeat'
    }} >
      <Section small className="py-1">
        <h2 className="mb-6 mt-1text-center text-gradient-info mt-2 mb-4">Cart sol: (link QR) {PartnerUser} <FontAwesomeIcon icon={faShoppingCart} size="lg" className="text-info text-xl" /></h2>
        <Row className="g-5">
          <Col xs={12} lg={8}>
            <EcomCartTable products={cartItems} />
          </Col>
          <Col xs={12} lg={4}>
            <EcomCartSummaryCard />
          </Col>
        </Row>
      </Section>
      <AddressSection />
    </div>
    <Footer /></>
  );
};

export default Cart;