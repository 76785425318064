import bgLeft from 'assets/img/bg/bg-left-23.png';
import bgRight from 'assets/img/bg/bg-right-23.png';
import Button from 'components/base/Button';  
import { LinkedInEmbed } from '@vip30/react-social-media-embed';
import { Col, Row, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import {
  faFacebookSquare,
  faInstagram,
  faLinkedin,
  faLinkedinIn,
  faTiktok,
  faTwitter,
  faXTwitter
} from '@fortawesome/free-brands-svg-icons';

const bluishColor = '#a3d7ff'; // Color between #60d5ff and white

const TeamSection = () => {
  return (
    <section id="whatsnew" className="alternate-landing-team position-relative" style={{ marginBottom: '0', padding: '20px 0' }}>
      <div
        className="position-absolute w-100 h-100 start-0 end-0 top-0 bg-body dark__bg-gray-1000"
        style={{ transform: 'skewY(-6deg)', transformOrigin: 'right' }}
      />
      <div
        className="bg-holder d-none d-xl-block position-absolute top-0 start-0 end-0"
        style={{
          backgroundImage: `url(${bgLeft})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'left center',
          height: '100%'
        }}
      />
      <div
        className="bg-holder d-none d-xl-block position-absolute top-0 start-0 end-0"
        style={{
          backgroundImage: `url(${bgRight})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'right center',
          height: '100%'
        }}
      />
      <div className="container-small position-relative z-index-1" style={{ padding: '0', marginTop: '20px' }}>
        <div className="text-center mb-4">
          <h1 className="text-gradient-info mt-4 mb-4">Latest News</h1>
        </div>
      </div>
      <Container>
        <h2 className="text-light text-center mb-10">
          Stay updated with Hazbot’s latest news, releases, and industry insights.
        </h2>
        <Row className="justify-content-center align-items-stretch">
                      {/* 1st Posting */}
                      <Col xs={12} md={6} lg={4} className="mb-4">
          <a href="https://www.linkedin.com/company/creatimus/">
            <FontAwesomeIcon icon={faLinkedin} className="text-primary fs-6" />
          </a>
            <LinkedInEmbed
              url="https://www.linkedin.com/embed/feed/update/urn:li:share:7279336982126764033"
              postUrl="https://www.linkedin.com/posts/creatimus_hazbot-creatimus-hazmatinnovation-activity-7279336983351513088-UMjk?utm_source=share&utm_medium=member_desktop"
              width="100%"
              height="520"
            />
          </Col>
                    {/* 2nd Posting */}
          <Col xs={12} md={6} lg={4} className="mb-4">
          <a href="https://www.linkedin.com/company/creatimus/">
            <FontAwesomeIcon icon={faLinkedin} className="text-primary fs-6" />
          </a>
            <LinkedInEmbed
              url="https://www.linkedin.com/embed/feed/update/urn:li:share:7274578969100763137"
              postUrl="https://www.linkedin.com/feed/update/urn:li:activity:7274578970124165120"
              width="100%"
              height="520"
            />
          </Col>
                     {/* 3rd Posting */}
          <Col xs={12} md={6} lg={4} className="mb-4">
          <a href="https://www.linkedin.com/company/creatimus/">
            <FontAwesomeIcon icon={faLinkedin} className="text-primary fs-6" />
          </a>
            <LinkedInEmbed
              url="https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7268026566243180544"
              postUrl="https://www.linkedin.com/feed/update/urn:li:activity:7268026685587898369"
              width="100%"
              height="520"
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default TeamSection;
